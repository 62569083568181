// noinspection JSLint
import jQuery from "jquery"; // jshint ignore:line
// noinspection JSLint
window.$ = window.jQuery = jQuery;
// noinspection JSLint
import * as M from "materialize-css"; // jshint ignore:line
import noUiSlider from "materialize-css/extras/noUiSlider/nouislider";
window.noUiSlider = noUiSlider;

document.addEventListener("DOMContentLoaded", function() {
  var elems = document.querySelectorAll(".modal");
  var instances = M.Modal.init(elems);
  var elems = document.querySelectorAll(".collapsible");
  var instances = M.Collapsible.init(elems, { accordion: false });
  var elems = document.querySelectorAll(".dropdown-trigger");
  var instances = M.Dropdown.init(elems);
});

window.setUrlParameter = function(url, key, value) {
  var urlSplit = url.split("?");
  var baseUrl = urlSplit[0],
    urlQueryString = "?" + urlSplit[1],
    newParam = key + "=" + value,
    params = "?" + newParam;

  // If the "search" string exists, then build params from it
  if (urlSplit[1]) {
    var updateRegex = new RegExp("([?&])" + key + "[^&]*");
    var removeRegex = new RegExp("([?&])" + key + "=[^&;]*[&;]?");

    if (typeof value === "undefined" || value === null || value === "") {
      // Remove param if value is empty
      params = urlQueryString.replace(removeRegex, "$1");
      params = params.replace(/[&;]$/, "");
    } else if (urlQueryString.match(updateRegex) !== null) {
      // If param exists already, update it
      params = urlQueryString.replace(updateRegex, "$1" + newParam);
    } else {
      // Otherwise, add it to end of query string
      params = urlQueryString + "&" + newParam;
    }
  }

  // no parameter was set so we don't need the question mark
  params = params === "?" ? "" : params;

  return baseUrl + params;
};

function createFunctionWithTimeout(callback, opt_timeout) {
  var called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}

$(document).ready(function() {
  $(".more-shops-btn").click(function(e) {
    $("#modal-more-shops > .modal-content").html(
      '<div class="progress">\n' +
        '      <div class="indeterminate"></div>\n' +
        "  </div>"
    );
    var url = $(e.target).attr("data-source");
    $.get(url, function(data) {
      // use this method you need to handle the response from the view
      // with rails Server-Generated JavaScript Responses this is portion will be in a .js.erb file
      $(".modal-content").html(data);
    });
  });

  $(".card a").click(function() {
    //console.group('Analytics');
    let adv = $(this).attr("adv-id");
    let shop = $(this).attr("shop-id");
    let product_id = $(this).attr("product-id");
    let elem_id = $(this).attr("elem-id");
    let elem_href = $(this).attr("href");
    //if (navigator.sendBeacon) {
    //  gtag("event", elem_id + "-" + adv + "-" + shop, {
    //    event_category: "bridge",
    //    event_label: product_id,
    //    transport: "beacon"
    //  });
    //} else {
    //  gtag("event", elem_id + "-" + adv + "-" + shop, {
    //    event_category: "bridge",
    //    event_label: product_id,
    //    hitCallback: createFunctionWithTimeout(function() {
    //      window.open(elem_href, "_blank");
    //    })
    //  });
    //  return false;
    //}
    //console.groupEnd('Analytics')
  });

  $(".merchant-filter").on("click", function(e) {
    window.location.href = setUrlParameter(
      setUrlParameter(
        window.location.href,
        "store",
        $(this).find("input")[0].value
      ),
      "page",
      ""
    );
  });
  $(".brand-filter").on("click", function(e) {
    window.location.href = setUrlParameter(
      setUrlParameter(
        window.location.href,
        "brand",
        $(this).find("input")[0].value
      ),
      "page",
      ""
    );
  });
  $(".discount-filter").on("click", function(e) {
    window.location.href = setUrlParameter(
      setUrlParameter(
        window.location.href,
        "discount",
        $(this).find("input")[0].value
      ),
      "page",
      null
    );
  });
  $("#sortBy").on("change", function(e) {
    window.location.href = setUrlParameter(
      setUrlParameter(window.location.href, "order", $("#sortBy")[0].value),
      "page",
      ""
    );
  });
  $("#clearSearch").on("click", function(e) {
    let input = $("input#query");
    input.val("");
    input.focus();
  });
  $("#showFilter").on("click", function(e) {
    $("#filters").toggle();
  });
});
